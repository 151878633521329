import { RecoilRoot } from 'recoil';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AboutPage, CartPage, CatalogPage, ErrorPage, MainPage, ProductPage, ShippingPage } from 'pages';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './index.css';

const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <MainPage />,
      },
      {
        path: '/products',
        element: <CatalogPage />,
      },
      {
        path: '/products/:productPermalink',
        element: <ProductPage />,
      },
      {
        path: '/cart',
        element: <CartPage />,
      },
      {
        path: '/shipping',
        element: <ShippingPage />,
      },
      {
        path: '/about',
        element: <AboutPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <RecoilRoot>
    <RouterProvider router={router} />
  </RecoilRoot>
);
