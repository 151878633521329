import { ICartProductFull } from 'common/types';
import { Stack } from 'components';
import { ApiImageService } from 'common/api';
import { NavigateService } from 'common/service';
import { formatPrice } from 'common/helpers';
import { IProduct } from 'common/models';
import './style.css';
import { useRecoilValue } from 'recoil';
import { deviceAtom } from 'common/recoil';

interface IProps {
  cartProduct: ICartProductFull;
  onIncreaseClick: (product: IProduct) => void;
  onDecreaseClick: (product: IProduct) => void;
}

export function CartProductCard(props: IProps) {
  const device = useRecoilValue(deviceAtom);

  const imageSrc =
    props.cartProduct.product.imageIds.length > 0
      ? ApiImageService.getInstance().getUrl(props.cartProduct.product.imageIds[0])
      : null;

  const imageBlock =
    imageSrc == null ? (
      <div className='cart-product-image-J3ks'></div>
    ) : (
      <img
        className='cart-product-image-J3ks'
        alt={`${props.cartProduct.product.title}`}
        src={imageSrc}
      />
    );

  const totalPrice = props.cartProduct.count * Number(props.cartProduct.product.price.replace(',', '.'));
  const totalPriceString = Number.isNaN(totalPrice) ? '-' : formatPrice(totalPrice.toString());

  if (device === 'mobile') {
    return (
      <Stack
        orientation='vertical'
        gap={5}
      >
        <a
          className='cart-product-title-nshd3'
          href={NavigateService.getInstance().productPageUrl(props.cartProduct.product.id)}
        >
          {props.cartProduct.product.title}
        </a>
        <Stack
          orientation='horizontal'
          className='product-info-row-Jsyw'
          gap={5}
        >
          {imageBlock}
          <div />
          <Stack
            orientation='horizontal'
            gap={10}
          >
            <div>{formatPrice(props.cartProduct.product.price)}</div>
            <div>x</div>
            <Stack
              orientation='horizontal'
              gap={5}
            >
              <input
                className='change-count-button-mshd'
                type='button'
                value='+'
                onClick={() => props.onIncreaseClick(props.cartProduct.product)}
              />
              <div>
                {props.cartProduct.count} {props.cartProduct.product.measureUnit}
              </div>
              <input
                className='change-count-button-mshd'
                type='button'
                value='-'
                onClick={() => props.onDecreaseClick(props.cartProduct.product)}
              />
            </Stack>
            <div>=</div>
          </Stack>
        </Stack>
        <Stack
          orientation='horizontal'
          contentFill={[1, 0]}
        >
          <div />
          <div>{totalPriceString}</div>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      orientation='vertical'
      gap={5}
    >
      <a
        className='cart-product-title-nshd3'
        href={NavigateService.getInstance().productPageUrl(props.cartProduct.product.id)}
      >
        {props.cartProduct.product.title}
      </a>
      <Stack
        orientation='horizontal'
        className='product-info-row-Jsyw'
        gap={5}
      >
        {imageBlock}
        <div />
        <Stack
          orientation='horizontal'
          gap={10}
        >
          <div>{formatPrice(props.cartProduct.product.price)}</div>
          <div>x</div>
          <Stack
            orientation='horizontal'
            gap={5}
          >
            <input
              className='change-count-button-mshd'
              type='button'
              value='+'
              onClick={() => props.onIncreaseClick(props.cartProduct.product)}
            />
            <div>
              {props.cartProduct.count} {props.cartProduct.product.measureUnit}
            </div>
            <input
              className='change-count-button-mshd'
              type='button'
              value='-'
              onClick={() => props.onDecreaseClick(props.cartProduct.product)}
            />
          </Stack>
          <div>=</div>
          <div>{totalPriceString}</div>
        </Stack>
      </Stack>
    </Stack>
  );
}
