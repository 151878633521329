import { MessageCard, Stack } from 'components';

export function ShippingCard() {
  return (
    <MessageCard title='ОБЩАЯ ИНФОРМАЦИЯ О ДОСТАВКЕ'>
      <Stack
        orientation='vertical'
        gap={5}
      >
        <p>
          Мы осуществляем доставку по всей территории <b>Российской Федерации</b> и <b>странам СНГ</b>.
        </p>
        <p>
          Отправка товаров выполняется из города <b>Чита</b>.
        </p>
        <br />
        <p>
          Стоимость доставки: <b>300 Рублей за отправку</b> + стоимость доставки транспортной компанией или почтой.
        </p>
        <br></br>
        <p>Мы выполняем отправку следующими компаниями:</p>
        <ul>
          <li>
            <a href='https://www.pochta.ru/'>Почта России</a> (до 20 кг);
          </li>
          <li>
            Транспортная компания <a href='https://www.dellin.ru/'>«Деловые линии»</a>;
          </li>
          <li>
            Транспортная компания <a href='https://www.cdek.ru/'>«СДЭК»</a>;
          </li>
          <li>
            Транспортная компания <a href='https://nrg-tk.ru/'>«Энергия»</a>;
          </li>
          <li>
            Транспортная компания <a href='https://zanoch.ru/'>«Ночной экспресс»</a>;
          </li>
          <li>
            Транспортная компания <a href='https://tk-kit.com/'>«КИТ»</a>.
          </li>
        </ul>
      </Stack>
    </MessageCard>
  );
}
