import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { IPropsContainer } from 'common/types';
import { DeviceService } from 'common/service';
import { deviceAtom } from 'common/recoil';
import { Footer, Header, Stack } from 'components';
import './style.css';

interface IPageProps extends IPropsContainer {
  title: string;
  description?: string;
}

function getPageTitle(input: string): string {
  return `${input} — Гидравлика75`;
}

function setPageDescription(description: string) {
  const metaTags = document.getElementsByTagName('meta');

  for (let i = 0; i < metaTags.length; i++) {
    if (metaTags[i].name !== 'description') {
      continue;
    }

    metaTags[i].content = description;
    break;
  }
}

export function Page(props: IPageProps) {
  const [device, setDevice] = useRecoilState(deviceAtom);

  useEffect(() => {
    document.title = getPageTitle(props.title);

    if (props.description != null) {
      setPageDescription(props.description);
    }
  }, [props.title, props.description]);

  useEffect(() => {
    DeviceService.getInstance().setHandler(setDevice);
  }, []); // eslint-disable-line

  let className = 'page-Uej2';

  if (props.className != null) {
    className = `${className} ${props.className}`;
  }

  const pageContent = device === 'mobile' ? 'content-mobile-NSdu' : 'content-desktop-nJSi';

  return (
    <Stack
      orientation='vertical'
      fill
      contentFill={[0, 1, 0]}
      className={className}
      style={props.style}
    >
      <Header />
      <div className={pageContent}>{props.children}</div>
      <Footer />
    </Stack>
  );
}
