import { useEffect, useRef } from 'react';
import { IProduct } from 'common/models';
import { Stack } from 'components';
import { ProductCard } from 'shared/components';
import { useImmer } from 'use-immer';

interface IProps {
  products: IProduct[];
  hideUnfilled?: boolean;
  maxRows?: number;
}

interface IState {
  width?: number;
}

export function ProductList(props: IProps) {
  const gap = 10;
  const minProductWidth = 200;
  const maxProductWidth = 300;
  const minProductsAtRow = 2;
  const maxProductsAtRow = 5;

  const [state, updateState] = useImmer<IState>({});
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    updateStateWidth();
    window.addEventListener('resize', updateStateWidth);
    return () => window.removeEventListener('resize', updateStateWidth);
  }, []); // eslint-disable-line

  const updateStateWidth = () => {
    const prevSize = state.width;
    const isUpdate = prevSize == null || (ref.current?.clientWidth != null && Math.abs(ref.current?.clientWidth - prevSize) > 3);

    if (isUpdate) {
      updateState(x => {
        x.width = ref.current?.clientWidth;
      });
    }
  };

  if (ref.current == null) {
    return (
      <div
        style={{ height: '800px' }}
        ref={ref}
      />
    );
  }

  const minWidthCount = ref.current.clientWidth / (minProductWidth + gap);
  const maxWidthCount = ref.current.clientWidth / (maxProductWidth + gap);
  let cardCount = Math.round((minWidthCount + maxWidthCount) / 2);

  if (cardCount < minProductsAtRow) {
    cardCount = minProductsAtRow;
  }

  if (cardCount > maxProductsAtRow) {
    cardCount = maxProductsAtRow;
  }

  const cardHeight = (ref.current.clientWidth / cardCount) * 1.5;

  const rows: React.ReactNode[] = [];
  let rowCount = props.hideUnfilled
    ? Math.floor(props.products.length / cardCount)
    : Math.ceil(props.products.length / cardCount);

  if (props.maxRows !== undefined && props.maxRows < rowCount) {
    rowCount = props.maxRows;
  }

  for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
    const cards: React.ReactNode[] = [];

    for (let colIndex = 0; colIndex < cardCount; colIndex++) {
      const productIndex = rowIndex * cardCount + colIndex;

      if (productIndex >= props.products.length) {
        cards.push(<div key={colIndex} />);
        continue;
      }

      const product = props.products[productIndex];
      cards.push(
        <ProductCard
          style={{ height: `${cardHeight}px` }}
          key={colIndex}
          product={product}
        />
      );
    }

    const stackFills = Array(cards.length).fill(1);

    rows.push(
      <Stack
        key={rowIndex}
        orientation='horizontal'
        gap={gap}
        contentFill={stackFills}
      >
        {cards}
      </Stack>
    );
  }

  return (
    <Stack
      orientation='vertical'
      gap={gap}
      ref={ref}
    >
      {rows}
    </Stack>
  );
}
