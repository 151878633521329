import { Stack } from 'components';
import './style.css';
import { useState } from 'react';
import { AddressBarService, NavigateService } from 'common/service';

export function SearchBar() {
  const [value, setValue] = useState(AddressBarService.getInstance().getQueryParam('filter') ?? '');

  const handleSearchClick = () => {
    const navigateService = NavigateService.getInstance();

    if (value === '') {
      navigateService.productsPage({}, true);
    } else {
      navigateService.productsPage({ filter: value }, true);
    }
  };

  return (
    <Stack
      orientation='horizontal'
      className='search-bar-sjsdu'
      contentFill={[1, 0]}
    >
      <input
        type='text'
        placeholder='Поиск товаров'
        value={value}
        onChange={e => setValue(e.target.value)}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            handleSearchClick();
          }
        }}
      />

      <div
        role='button'
        tabIndex={0}
        className='find-button-2Hdy'
        onClick={handleSearchClick}
      >
        <i className='bi bi-search' />
      </div>
    </Stack>
  );
}
