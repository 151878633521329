import { IPropsContainer } from 'common/types';
import './style.css';

interface ILinkProps extends IPropsContainer {
  href?: string;
  newTab?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

export function Link(props: ILinkProps) {
  if (props.href != null && props.onClick != null) {
    throw new Error('Must be only href or only onClick!');
  }

  let className = 'link-JShGS';

  if (!props.disabled) {
    className += ' enabled-NZhaY';
  }

  if (props.className != null) {
    className = `${className} ${props.className}`;
  }

  const handleClick = () => {
    if (props.disabled || props.onClick == null) {
      return;
    }

    props.onClick();
  };

  if (props.href != null && !props.disabled) {
    return (
      <a
        className={className}
        href={props.href}
        target={props.newTab ? '_blank' : undefined}
        rel={props.newTab ? 'noopener noreferrer' : undefined}
      >
        {props.children}
      </a>
    );
  }

  return (
    <div
      role='button'
      tabIndex={0}
      className={className}
      onClick={handleClick}
    >
      {props.children}
    </div>
  );
}
