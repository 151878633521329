import { IProduct } from 'common/models';
import { CartService } from 'common/service';
import { Button } from 'components';
import './style.css';

interface IProps {
  product: IProduct;
}

export function ButtonBuyNow(props: IProps) {
  const handleBuyNowClick = () => {
    const cartService = CartService.getInstance();
    const cartProducts = cartService.get();
    const cartProduct = cartProducts.find(x => x.productPermalink === props.product.permalink);

    if (cartProduct == null) {
      cartService.set(props.product, 1);
    }

    window.location.href = '/cart';
  };

  return (
    <Button
      onClick={handleBuyNowClick}
      className='buy-now-button-7jdu'
    >
      КУПИТЬ СЕЙЧАС
    </Button>
  );
}
