import { Link, Stack } from 'components';
import './style.css';

interface IProps {
  href: string;
  icon: React.ReactNode;
  title: string;
  orientation: 'horizontal' | 'vertical';
}

export function LinkButton(props: IProps) {
  return (
    <Link
      href={props.href}
      className='header-link-ANsj'
    >
      <Stack
        orientation={props.orientation}
        gap={5}
      >
        <div
          className={props.orientation === 'vertical' ? 'icon-nAJs' : undefined}
        >
          {props.icon}
        </div>
        {props.title}
      </Stack>
    </Link>
  );
}
