import { IProduct } from 'common/models';
import './style.css';
import { Stack } from 'components';
import { ApiImageService } from 'common/api';
import { formatPrice } from 'common/helpers';

interface IProps {
  product: IProduct;
  style?: React.CSSProperties;
}

export function ProductCard(props: IProps) {
  const noImageBlock = (
    <div className='no-image-Nansy'>
      <i className='bi bi-card-image' />
    </div>
  );
  const imageBlock =
    props.product.imageIds.length > 0 ? (
      <div className='image-container-GKsh'>
        <a href={`/products/${props.product.permalink}`}>
          <img
            className='image-Jsud'
            src={ApiImageService.getInstance().getUrl(props.product.imageIds[0])}
            alt={props.product.title}
          />
        </a>
      </div>
    ) : (
      noImageBlock
    );

  return (
    <div
      className='product-card-container-34H'
      style={props.style}
      onClick={() => (window.location.href = `/products/${props.product.permalink}`)}
    >
      <Stack
        orientation='vertical'
        className='product-card-7Hsy'
        contentFill={[1, '80px']}
        gap={5}
      >
        {imageBlock}
        <Stack
          orientation='vertical'
          className='description-Nahd'
          gap={5}
          contentFill={[1, 0]}
        >
          <a
            className='title-9Y3'
            href={`/products/${props.product.permalink}`}
          >
            {props.product.title}
          </a>
          <div className='price-NAj'>{formatPrice(props.product.price)}</div>
        </Stack>
      </Stack>
    </div>
  );
}
