import { Stack } from 'components';
import './style.css';

export function AboutOrderBlock() {
  return (
    <Stack
      orientation='vertical'
      gap={10}
      className='order-info-block-Nshe'
    >
      <h2>О ЗАКАЗЕ</h2>
      <p>После оформления заказа с вами свяжется менеджер для его подтверждения.</p>
      <p>Обратите внимание, что менеджеры работает по часовому поясу города Читы.</p>
    </Stack>
  );
}
