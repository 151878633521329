import { Stack } from 'components';
import { AboutCard, ContactsCard } from '../components';

export function MobileAbout() {
  return (
    <Stack
      orientation='vertical'
      className='page-content-NAjSy'
      gap={10}
    >
      <h1>О НАС</h1>
      <AboutCard />
      <ContactsCard />
    </Stack>
  );
}
