import { ICartProductFull } from 'common/types';
import { Button, Stack } from 'components';
import { CartProductCard } from '..';
import './style.css';
import { formatPrice } from 'common/helpers';
import { IProduct } from 'common/models';

interface IProps {
  cartProducts: ICartProductFull[];
  onIncreaseClick: (product: IProduct) => void;
  onDecreaseClick: (product: IProduct) => void;
}

export function CartProductList(props: IProps) {
  let totalPrice = 0;

  for (const cartProduct of props.cartProducts) {
    totalPrice += Number(cartProduct.product.price.replace(',', '.')) * cartProduct.count;
  }

  const productList =
    props.cartProducts.length === 0 ? (
      <div className='cart-list-nhsya'>
        <Stack
          orientation='horizontal'
          contentFill={[1, '250px', 1]}
          className='no-products-in-cart-Nshat'
        >
          <div />
          <Stack
            orientation='vertical'
            gap={10}
          >
            В КОРЗИНЕ НЕТ ТОВАРОВ
            <Button href='/'>К ПОКУПКАМ</Button>
          </Stack>
          <div />
        </Stack>
      </div>
    ) : (
      <Stack
        className='cart-list-nhsya'
        orientation='vertical'
        gap={20}
        contentFill={[1, 0]}
      >
        <Stack
          orientation='vertical'
          gap={10}
        >
          {props.cartProducts.map(x => (
            <CartProductCard
              key={x.product.id}
              cartProduct={x}
              onDecreaseClick={props.onDecreaseClick}
              onIncreaseClick={props.onIncreaseClick}
            />
          ))}
        </Stack>
        <Stack
          orientation='horizontal'
          className='price-data-Nshdy'
        >
          <Stack
            orientation='horizontal'
            gap={10}
          >
            ИТОГО:
            <b>{formatPrice(totalPrice)}</b>
          </Stack>
        </Stack>
      </Stack>
    );

  return (
    <Stack
      orientation='vertical'
      gap={10}
    >
      <h1>ТОВАРЫ В КОРЗИНЕ</h1>
      {productList}
    </Stack>
  );
}
