import { Guid } from 'common/types';
import { ICategoryBrief } from 'common/models';
import { Stack, Link } from 'components';
import { CategoryButton } from '../category-button/CategoryButton';

interface ICategoriesBlockProps {
  coreCategoryId?: string;
  categories: ICategoryBrief[];
  isMobile?: boolean;
  hideTitle?: boolean;
}

export function CategoriesBlock(props: ICategoriesBlockProps) {
  const coreCategories = props.categories.filter(x => x.parentId === (props.coreCategoryId ?? Guid.empty()));

  const content = (
    <Stack
      orientation='vertical'
      gap={5}
    >
      {coreCategories.map(x => (
        <CategoryButton
          isMobile={props.isMobile ?? false}
          category={x}
          key={x.id}
          categories={props.categories}
        />
      ))}
    </Stack>
  );

  if (props.hideTitle) {
    return content;
  }

  return (
    <Stack
      orientation='vertical'
      gap={10}
    >
      <Link
        className='title-Jsud'
        href={`/products`}
      >
        <Stack
          orientation='horizontal'
          gap={5}
        >
          <h1>КАТЕГОРИИ ТОВАРОВ</h1>
          <div className='title-chevron-sjdhSu'>
            <i className='bi bi-chevron-right'></i>
          </div>
        </Stack>
      </Link>

      {content}
    </Stack>
  );
}
