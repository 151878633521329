import { IProduct } from 'common/models';
import { formatPrice } from 'common/helpers';
import { Stack } from 'components';
import './style.css';

interface IProps {
  product: IProduct;
}

export function ProductPrice(props: IProps) {
  const price = formatPrice(props.product.price);

  return (
    <Stack
      orientation='horizontal'
      contentFill={[0, 1]}
      fill
    >
      <div className='price-title-block'>ЦЕНА</div>
      <div className='price-value-block'>{price}</div>
    </Stack>
  );
}
