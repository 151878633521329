import { Button, Page, Stack } from 'components';
import { useRouteError } from 'react-router-dom';
import './style.css';

export function ErrorPage() {
  const errorCode = (useRouteError() as any)?.status;

  const description = 'При выполнении какого-либо запроса произошла ошибка. Повторите запрос.';

  return (
    <Page
      title={`Ошибка ${errorCode ?? 999}`}
      description={description}
    >
      <Stack
        orientation='horizontal'
        fill
        contentFill={[1, 0, 1]}
      >
        <div />
        <Stack
          className='error-block-Osnhf'
          orientation='vertical'
          gap={10}
        >
          <h1 className='error-header'>ОШИБКА</h1>
          <div className='error-code-Jsy'>{errorCode ?? '---'}</div>
          {errorCode === 404 ? (
            <div className='error-description-Ndyy'>К сожалению, такой страницы не существует, но зато есть много других!</div>
          ) : (
            <div className='error-description-Ndyy'>Произошла неожиданная ошибка, попробуйте ещё раз!</div>
          )}
          <Button
            className='return-to-main-button-ndhs'
            href='/'
          >
            Вернуться на главную
          </Button>
        </Stack>
        <div />
      </Stack>
    </Page>
  );
}
