import { MessageCard } from 'components';

export function AdditionInfoCard() {
  return (
    <MessageCard title='ДОПОЛНИТЕЛЬНАЯ ИНФОРМАЦИЯ'>
      <p>
        При возникновении дополнительных вопросов, вы можете связаться с нами с помощью контактов, указанных на странице{' '}
        <a href='/about'>«О нас»</a>.
      </p>
    </MessageCard>
  );
}
