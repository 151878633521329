import { IProduct } from 'common/models';
import { Stack } from 'components';
import './style.css';

interface IProps {
  product: IProduct;
}

export function ProductDescription(props: IProps) {
  const productDescription =
    props.product.description?.length > 0 ? (
      <div>{props.product.description}</div>
    ) : (
      <div className='no-description-Jdhg3'>Нет описания...</div>
    );

  return (
    <Stack
      orientation='vertical'
      className='description-block-hdyT3'
      gap={10}
    >
      <Stack
        orientation='horizontal'
        gap={5}
        className='description-title-Udhsy'
      >
        <i className='bi bi-file-earmark-text' />
        <div>Описание товара</div>
      </Stack>
      {productDescription}
    </Stack>
  );
}
