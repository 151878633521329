import { ApiClient } from 'common/api';
import { ICartProductBrief } from 'common/types';

enum OrderNodes {
  Orders = '/orders',
}

export class ApiOrderService {
  private static instance: ApiOrderService | null = null;

  private apiService: ApiClient;

  private constructor() {
    this.apiService = ApiClient.getInstance();
  }

  static getInstance() {
    if (this.instance == null) {
      this.instance = new ApiOrderService();
    }

    return this.instance;
  }

  async create(name: string, telephone: string, city: string, comment: string, products: ICartProductBrief[]) {
    const address = `${OrderNodes.Orders}`;
    const request = {
      name: name,
      telephone: telephone,
      comment: comment,
      city: city,
      products: Object.fromEntries(products.map(x => [x.productPermalink, x.count])),
    };

    await this.apiService.sendRequest('post', address, request);
  }
}
