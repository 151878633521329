import { IProduct } from 'common/models';
import { Button, Modal, Stack } from 'components';
import './style.css';
import { useState } from 'react';
import { ApiImageService } from 'common/api';

interface IProps {
  product: IProduct;
}

export function ProductImages(props: IProps) {
  const [imageId, setImageId] = useState<string | null>(props.product.imageIds.length > 0 ? props.product.imageIds[0] : null);
  const [isImageOpened, setImageOpened] = useState(false);

  return (
    <div>
      {isImageOpened && imageId != null && (
        <Modal
          size='medium'
          onClose={() => setImageOpened(false)}
        >
          <Stack
            orientation='vertical'
            gap={20}
          >
            <div className='modal-image-block-4h3gsS'>
              <img
                className='image-s4hy2'
                src={ApiImageService.getInstance().getUrl(imageId)}
              />
            </div>
            <Button
              className='close-modal-button-Hsg3'
              onClick={() => setImageOpened(false)}
            >
              Закрыть
            </Button>
          </Stack>
        </Modal>
      )}
      <Stack
        orientation='vertical'
        gap={5}
      >
        <div
          className='image-block-J3hs'
          onClick={() => setImageOpened(true)}
        >
          {imageId != null ? (
            <img
              className='image-jHdy3s'
              alt='main-image-picture'
              src={ApiImageService.getInstance().getUrl(imageId)}
            ></img>
          ) : (
            <Stack
              orientation='vertical'
              contentFill={[1, 0, 1]}
              fill
            >
              <div />
              <div className='empty-image-ndh3'>
                <i className='bi bi-card-image' />
              </div>
              <div />
            </Stack>
          )}
        </div>
        <Stack
          orientation='horizontal'
          className='miniatures-jy4hs'
          contentFill={[1, 0, 1]}
        >
          <div />
          <Stack
            orientation='horizontal'
            gap={5}
          >
            {props.product.imageIds.map(x => (
              <div
                key={x}
                className='image-miniature-j4jd'
                onClick={() => setImageId(x)}
              >
                <img
                  className='image-jHdy3s'
                  src={ApiImageService.getInstance().getUrl(x)}
                  alt='image-miniature'
                />
              </div>
            ))}
          </Stack>
          <div />
        </Stack>
      </Stack>
    </div>
  );
}
