import { MessageCard, Stack } from 'components';

export function ContactsCard() {
  return (
    <MessageCard title='КОНТАКТЫ'>
      <Stack orientation='vertical'>
        <p>Вы можете связаться с нами следующими способами:</p>
        <ul>
          <li>
            По Телефону: <a href='tel:7-914-350-11-15'>+7 (914) 350-11-15</a>, мы работаем с 09:00 до 18:00 (кроме субботы и
            воскресенья);
          </li>
          <li>
            По WhatsApp: <a href='https://wa.me/79143501115'>+7 (914) 350-11-15</a>;
          </li>
          <li>
            По E-Mail: <a href='mailto:hydravlyka75@mail.ru'>hydravlyka75@mail.ru</a>.
          </li>
        </ul>
        <p>
          Так же вы можете приехать к нам в магазин лично. Мы находимся по следующиму адресу:{' '}
          <a href='https://go.2gis.com/hhn42'>г. Чита, ул. Инструментальная 7</a>
        </p>

        <div style={{ overflow: 'hidden', width: 'calc(100% - 40px)', margin: '20px' }}>
          <iframe
            title='2ГИС карта нашего магазина'
            style={{ margin: 'auto', display: 'block', border: 'none' }}
            height={400}
            width={500}
            src='https://widgets.2gis.com/widget?type=firmsonmap&options=%7B%22pos%22%3A%7B%22lat%22%3A52.06896819862964%2C%22lon%22%3A113.47748279571535%2C%22zoom%22%3A16%7D%2C%22opt%22%3A%7B%22city%22%3A%22chita%22%7D%2C%22org%22%3A%2270000001080424100%22%7D'
          />
        </div>
        <p>P.S. Обратите внимание, что магазин находится в г. Чита и часы работы указаны в часовом пояск МСК+6.</p>
      </Stack>
    </MessageCard>
  );
}
