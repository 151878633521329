export type DeviceType = 'mobile' | 'desktop';

const mobileDeviceWidth = 1000;
const step = 10;

export class DeviceService {
  private currentDeviceType: DeviceType;
  private currentInnerWidth = window.innerWidth;
  private handler: ((device: DeviceType) => void) | null = null;

  private static instance: DeviceService | null = null;

  private constructor() {
    this.currentDeviceType = this.getCurrentDeviceType();

    window.addEventListener('resize', () => {
      if (Math.abs(window.innerWidth - this.currentInnerWidth) < step) {
        return;
      }

      const updatedDeviceType = this.getCurrentDeviceType();
      if (updatedDeviceType !== this.currentDeviceType) {
        this.currentInnerWidth = window.innerWidth;
        this.currentDeviceType = this.getCurrentDeviceType();

        if (this.handler != null) {
          this.handler(updatedDeviceType);
        }
      }
    });
  }

  get device() {
    return this.currentDeviceType;
  }

  private getCurrentDeviceType(): DeviceType {
    if (window.innerWidth < mobileDeviceWidth) {
      return 'mobile';
    }

    return 'desktop';
  }

  setHandler(handler: (device: DeviceType) => void) {
    this.handler = handler;
  }

  static getInstance() {
    if (this.instance == null) {
      this.instance = new DeviceService();
    }

    return this.instance;
  }
}
