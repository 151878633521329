import { Label, Stack } from 'components';
import { IFrameProps } from '../../types';
import { CategoriesBlock, NoProducts, ProductList, WhyWeCard } from 'shared/components';
import { Pagination } from '../../components';
import './style.css';

export function DesktopCatalog(props: IFrameProps) {
  const frameTitle = (
    <Stack
      orientation='horizontal'
      contentFill={[1, 0]}
      gap={10}
    >
      <Label className='frame-title-U4Hsk'>
        <h1>{props.title}</h1>
      </Label>
      <div className='product-counter-j4y'>
        Найдено <b>{props.products.count}</b> объекта
      </div>
    </Stack>
  );

  const productsBlock =
    props.products.count > 0 ? (
      <Stack
        orientation='vertical'
        gap={20}
      >
        <ProductList products={props.products.result} />
        <Pagination
          page={props.page}
          maxPage={props.maxPage}
        />
      </Stack>
    ) : (
      <NoProducts />
    );

  return (
    <Stack
      className='page-content-NAjSy'
      orientation='horizontal'
      contentFill={['300px', 1]}
      gap={20}
    >
      <CategoriesBlock
        categories={props.categories}
        isMobile={false}
      />
      <Stack
        orientation='horizontal'
        contentFill={[1, 0]}
      >
        <Stack
          orientation='vertical'
          gap={20}
        >
          {frameTitle}
          {productsBlock}
          <WhyWeCard />
        </Stack>
        <div className='content-filler-nAJsy' />
      </Stack>
    </Stack>
  );
}
