import { Stack } from 'components';

export function WhyWeCard() {
  return (
    <Stack
      className='information-NAhsys'
      orientation='vertical'
      gap={10}
    >
      <h2 className='title-23sk'>ПОЧЕМУ ВЫБИРАЮТ НАС?</h2>
      <p>
        Мы уже несколько лет предлагаем нашим покупателям высококачественные уплотнения для гидравлических цилиндров, помогая
        сохранить технику в отличном состоянии.
      </p>
      <p>
        Мы прилагаем все усилия, чтобы наши продукты соответствовали самым высоким стандартам качества и надежности, и мы рады
        видеть, что они отвечают ожиданиям наших покупателей.
      </p>
      <Stack
        orientation='horizontal'
        gap={10}
      >
        <svg
          width='34px'
          height='34px'
          viewBox='0 0 34 34'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M24.0273 6.16407V3.97485H22.6094V6.16407H12.2969V3.97485H10.8789V6.16407H3.91797V29.0895H30.9883V6.16407H24.0273ZM5.33594 7.58481H10.8789V9.81923H12.2969V7.58481H22.6094V9.81923H24.0273V7.58481H29.5703V12.4702H5.33594V7.58481ZM29.5703 27.6688H5.33594V12.9868H29.5703V27.6688Z'
            fill='black'
          />
          <path
            d='M10.1699 18.2661C10.1699 18.5487 10.282 18.8197 10.4814 19.0196C10.6808 19.2194 10.9513 19.3317 11.2334 19.3317C11.5155 19.3317 11.7859 19.2194 11.9854 19.0196C12.1848 18.8197 12.2969 18.5487 12.2969 18.2661C12.2969 17.9835 12.1848 17.7125 11.9854 17.5127C11.7859 17.3128 11.5155 17.2006 11.2334 17.2006C10.9513 17.2006 10.6808 17.3128 10.4814 17.5127C10.282 17.7125 10.1699 17.9835 10.1699 18.2661Z'
            fill='#C12E42'
          />
          <path
            d='M16.3252 18.2661C16.3252 18.406 16.3527 18.5446 16.4061 18.6739C16.4596 18.8032 16.5379 18.9206 16.6367 19.0196C16.7354 19.1185 16.8527 19.197 16.9817 19.2506C17.1107 19.3041 17.249 19.3317 17.3887 19.3317C17.5283 19.3317 17.6666 19.3041 17.7956 19.2506C17.9247 19.197 18.0419 19.1185 18.1407 19.0196C18.2394 18.9206 18.3178 18.8032 18.3712 18.6739C18.4246 18.5446 18.4521 18.406 18.4521 18.2661C18.4521 18.1262 18.4246 17.9876 18.3712 17.8583C18.3178 17.7291 18.2394 17.6116 18.1407 17.5127C18.0419 17.4137 17.9247 17.3352 17.7956 17.2817C17.6666 17.2281 17.5283 17.2006 17.3887 17.2006C17.249 17.2006 17.1107 17.2281 16.9817 17.2817C16.8527 17.3352 16.7354 17.4137 16.6367 17.5127C16.5379 17.6116 16.4596 17.7291 16.4061 17.8583C16.3527 17.9876 16.3252 18.1262 16.3252 18.2661Z'
            fill='#C12E42'
          />
          <path
            d='M22.4839 18.2661C22.4839 18.5487 22.5959 18.8197 22.7954 19.0196C22.9948 19.2194 23.2653 19.3317 23.5474 19.3317C23.8294 19.3317 24.0999 19.2194 24.2994 19.0196C24.4988 18.8197 24.6108 18.5487 24.6108 18.2661C24.6108 17.9835 24.4988 17.7125 24.2994 17.5127C24.0999 17.3128 23.8294 17.2006 23.5474 17.2006C23.2653 17.2006 22.9948 17.3128 22.7954 17.5127C22.5959 17.7125 22.4839 17.9835 22.4839 18.2661Z'
            fill='#C12E42'
          />
          <path
            d='M10.1699 22.3894C10.1699 22.672 10.282 22.943 10.4814 23.1429C10.6808 23.3427 10.9513 23.455 11.2334 23.455C11.5155 23.455 11.7859 23.3427 11.9854 23.1429C12.1848 22.943 12.2969 22.672 12.2969 22.3894C12.2969 22.1068 12.1848 21.8358 11.9854 21.6359C11.7859 21.4361 11.5155 21.3239 11.2334 21.3239C10.9513 21.3239 10.6808 21.4361 10.4814 21.6359C10.282 21.8358 10.1699 22.1068 10.1699 22.3894Z'
            fill='#C12E42'
          />
          <path
            d='M16.3252 22.3894C16.3252 22.5293 16.3527 22.6679 16.4061 22.7972C16.4596 22.9264 16.5379 23.0439 16.6367 23.1429C16.7354 23.2418 16.8527 23.3203 16.9817 23.3738C17.1107 23.4274 17.249 23.455 17.3887 23.455C17.5283 23.455 17.6666 23.4274 17.7956 23.3738C17.9247 23.3203 18.0419 23.2418 18.1407 23.1429C18.2394 23.0439 18.3178 22.9264 18.3712 22.7972C18.4246 22.6679 18.4521 22.5293 18.4521 22.3894C18.4521 22.2495 18.4246 22.1109 18.3712 21.9816C18.3178 21.8524 18.2394 21.7349 18.1407 21.6359C18.0419 21.537 17.9247 21.4585 17.7956 21.405C17.6666 21.3514 17.5283 21.3239 17.3887 21.3239C17.249 21.3239 17.1107 21.3514 16.9817 21.405C16.8527 21.4585 16.7354 21.537 16.6367 21.6359C16.5379 21.7349 16.4596 21.8524 16.4061 21.9816C16.3527 22.1109 16.3252 22.2495 16.3252 22.3894Z'
            fill='#C12E42'
          />
          <path
            d='M22.4839 22.3894C22.4839 22.672 22.5959 22.943 22.7954 23.1429C22.9948 23.3427 23.2653 23.455 23.5474 23.455C23.8294 23.455 24.0999 23.3427 24.2994 23.1429C24.4988 22.943 24.6108 22.672 24.6108 22.3894C24.6108 22.1068 24.4988 21.8358 24.2994 21.6359C24.0999 21.4361 23.8294 21.3239 23.5474 21.3239C23.2653 21.3239 22.9948 21.4361 22.7954 21.6359C22.5959 21.8358 22.4839 22.1068 22.4839 22.3894Z'
            fill='#C12E42'
          />
        </svg>
        <div style={{ lineHeight: '34px' }}>Высокое качество товаров</div>
      </Stack>
      <Stack
        orientation='horizontal'
        gap={10}
      >
        <svg
          width='34px'
          height='34px'
          viewBox='0 0 1024 1024'
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M607.7 64.2c-102.9 0-186.3 83.4-186.3 186.3 0 30 15.9 66.6 38.3 103.3 15.2 24.8 33.3 49.7 51.7 72.6C559 486 607.7 533 607.7 533s17.7-17.1 42.3-43.8c14-15.3 30.3-33.6 46.7-53.8 47.9-58.7 97.3-132 97.3-184.9 0-102.9-83.4-186.3-186.3-186.3z m77.8 353.2c-8 10-16.1 19.6-23.9 28.6-7.8 9-15.4 17.5-22.5 25.3-12.5 13.7-23.4 25.1-31.4 33.3-12.5-12.8-32.3-33.5-53.8-58.5-7.8-9-15.8-18.5-23.8-28.5-13.5-16.7-25.5-32.7-36.1-48-10.1-14.6-18.8-28.4-26.1-41.5-17.6-31.6-26.6-57.8-26.6-77.8 0-44.4 17.3-86.2 48.7-117.6C521.4 101.3 563.2 84 607.6 84c44.4 0 86.2 17.3 117.6 48.7 31.4 31.4 48.7 73.2 48.7 117.6 0 20-8.9 46.1-26.5 77.6-14.8 26.9-35.6 56.9-61.9 89.5z'
            fill='#C12E42'
          />
          <path
            d='M607.7 183.1c36.9 0 67 30.1 67 67s-30.1 67-67 67-67-30.1-67-67 30-67 67-67m0-20c-48 0-87 38.9-87 87 0 48 38.9 87 87 87 48 0 87-38.9 87-87s-39-87-87-87z'
            fill='black'
          />
          <path
            d='M927.9 352.4l-195.7 70.3-35.6 12.8c-16.4 20.1-32.7 38.5-46.7 53.8l1.9 0.9v416.6l-12.7-5.9-46.3-21.6-212.2-98.9-8.6-4v-415l139.4 65c-18.4-23-36.5-47.8-51.7-72.6l-77.6-36.2-3.7-1.7-8.6-4-7.8-3.7-2.2 0.8L64 415.3v511.8L362 820l230.8 107.6 46.3 21.6 22.7 10.6L960 852.7V340.9l-32.1 11.5zM352 776.9l-4.9 1.8L108 864.6V446.2l244-87.7v418.4z m564 44.9l-244.2 87.7V491.1l5.1-1.8L916 403.4v418.4z'
            fill='black'
          />
        </svg>
        <div style={{ lineHeight: '34px' }}>Оперативная доставка</div>
      </Stack>
      <Stack
        orientation='horizontal'
        gap={10}
      >
        <svg
          width='34px'
          height='34px'
          viewBox='0 0 1024 1024'
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M601.5 531.8h278.8v16H601.5zM639.3 657.4h224v16h-224zM686.8 779h160.8v16H686.8z'
            fill='#C12E42'
          />
          <path
            d='M216.3 927.8H62.2V425.6h155.4l-1.3 502.2z m-110.1-44h66.2l1.1-414.2h-67.3v414.2zM822.1 927.8H268.9l-0.4-502L633.3 96.2l85.2 91.5-66.8 196.7h310L822.1 927.8z m-509.3-44H788l117-455.4H655.8l-65.5-0.1 78.1-229.9-37.8-40.5-318.1 287.4 0.3 438.5z'
            fill='black'
          />
        </svg>
        <div style={{ lineHeight: '34px' }}>Широкий ассортимент</div>
      </Stack>
      <Stack
        orientation='horizontal'
        gap={10}
      >
        <svg
          width='34px'
          height='34px'
          viewBox='0 0 1024 1024'
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M512 616.2m-10 0a10 10 0 1 0 20 0 10 10 0 1 0-20 0Z'
            fill='#C12E42'
          />
          <path
            d='M511.6 656.9m-10 0a10 10 0 1 0 20 0 10 10 0 1 0-20 0Z'
            fill='#C12E42'
          />
          <path
            d='M512.4 697.7m-10 0a10 10 0 1 0 20 0 10 10 0 1 0-20 0Z'
            fill='#C12E42'
          />
          <path
            d='M512 130.8c42.1 0 81.7 16.4 111.5 46.2s46.2 69.4 46.2 111.5-16.4 81.7-46.2 111.5c-29.8 29.8-69.4 46.2-111.5 46.2s-81.7-16.4-111.5-46.2c-29.8-29.8-46.2-69.4-46.2-111.5s16.4-81.7 46.2-111.5 69.4-46.2 111.5-46.2m0-44c-111.4 0-201.6 90.3-201.6 201.6C310.4 399.8 400.7 490 512 490c111.4 0 201.6-90.3 201.6-201.6S623.3 86.8 512 86.8zM512.3 523.5L84 681.4v255.7h856V681.4L512.3 523.5zM896 893.1H128V712.6l384.3-142.4L896 712.6v180.5z'
            fill='black'
          />
          <path
            d='M555.4 585.3l-1.4-0.5v159.9c0 11.7-4.8 22.3-12.4 30-7.7 7.7-18.3 12.4-30 12.4-23.4 0-42.4-19-42.4-42.4V585.3l-1.4 0.5-14.6 5.2v153.8c0 32.2 26.2 58.4 58.4 58.4S570 777 570 744.8V590.5l-14.6-5.2z'
            fill='#C12E42'
          />
        </svg>
        <div style={{ lineHeight: '34px' }}>Качественное обслуживние</div>
      </Stack>
    </Stack>
  );
}
