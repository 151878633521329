import { ICategoryBrief, IProduct } from 'common/models';
import { Stack } from 'components';
import { ButtonAddToCart, ButtonBuyNow, ProductDescription, ProductImages, ProductPrice, ProductTitle } from '../components';
import { ProductList } from 'shared/components';

interface IProps {
  categories: ICategoryBrief[];
  product: IProduct;
  products: IProduct[];
}

export function MobileProduct(props: IProps) {
  return (
    <Stack
      orientation='vertical'
      className='page-content-NAjSy'
      gap={20}
    >
      <ProductTitle product={props.product} />
      <Stack
        orientation='horizontal'
        contentFill={[1, '300px', 1]}
      >
        <div />
        <ProductImages product={props.product} />
        <div />
      </Stack>
      <ProductPrice product={props.product} />
      <Stack
        orientation='vertical'
        gap={10}
      >
        <ButtonAddToCart product={props.product} />
        <ButtonBuyNow product={props.product} />
      </Stack>

      <ProductDescription product={props.product} />

      <Stack
        orientation='vertical'
        gap={10}
      >
        <h2>ДРУГИЕ ТОВАРЫ</h2>
        <ProductList
          maxRows={3}
          products={props.products}
          hideUnfilled
        />
      </Stack>
    </Stack>
  );
}
