import { Stack, Label } from 'components';
import { Pagination } from 'pages/catalog-page/components';
import { ProductList, NoProducts, WhyWeCard, CategoriesBlock } from 'shared/components';
import { IFrameProps } from '../../types';
import './style.css';

export function MobileCatalog(props: IFrameProps) {
  const frameTitle = (
    <Stack
      orientation='horizontal'
      contentFill={[1, 0]}
      gap={10}
    >
      <Label className='frame-title-U4Hsk'>
        <h1>{props.title}</h1>
      </Label>
    </Stack>
  );

  const productsBlock =
    props.products.count > 0 ? (
      <Stack
        orientation='vertical'
        gap={20}
      >
        <ProductList products={props.products.result} />
        <Pagination
          page={props.page}
          maxPage={props.maxPage}
        />
      </Stack>
    ) : (
      <NoProducts />
    );

  const frameContent =
    props.category == null || props.categories.find(x => x.parentId === props.category?.id) == null ? (
      productsBlock
    ) : (
      <Stack
        orientation='vertical'
        gap={10}
      >
        <CategoriesBlock
          isMobile
          hideTitle
          categories={props.categories}
          coreCategoryId={props.category.id}
        />
        {productsBlock}
      </Stack>
    );

  return (
    <Stack
      className='page-content-NAjSy'
      orientation='vertical'
      gap={20}
    >
      {frameTitle}
      {frameContent}
      <WhyWeCard />
    </Stack>
  );
}
