import { deviceAtom } from 'common/recoil';
import { ModalLoader, Page, Stack } from 'components';
import { useRecoilValue } from 'recoil';
import { DesktopShipping, MobileShipping } from './frames';
import { useEffect, useState } from 'react';
import { ICategoryBrief } from 'common/models';
import { ApiCategoryService } from 'common/api';

export function ShippingPage() {
  const device = useRecoilValue(deviceAtom);
  const [categories, setCategories] = useState<ICategoryBrief[] | null>(null);
  useEffect(() => {
    loadCategories();
  }, []); // eslint-disable-line

  const loadCategories = async () => {
    const categoryService = ApiCategoryService.getInstance();
    const categories = await categoryService.getAll();
    setCategories(categories);
  };

  const description =
    'Информация о доставке товаров по всей стране и за её пределы, а так же об оплате заказа в магазине Гидравлика75.';

  if (categories == null) {
    return (
      <Page
        title='Доставка и оплата'
        description={description}
      >
        <ModalLoader />
      </Page>
    );
  }

  const frame = device === 'mobile' ? <MobileShipping /> : <DesktopShipping categories={categories} />;

  return (
    <Page
      title='Доставка и оплата'
      description={description}
    >
      {frame}
    </Page>
  );
}
