import { ApiCategoryService, ApiProductsService } from 'common/api';
import { ICategoryBrief, IProductSearch } from 'common/models';
import { deviceAtom } from 'common/recoil';
import { AddressBarService } from 'common/service';
import { ModalLoader, Page } from 'components';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useImmer } from 'use-immer';
import { DesktopCatalog, MobileCatalog } from './frames';

interface IState {
  page: number;
  categoryPermalink?: string;
  filter?: string;
  productSearch?: IProductSearch;
  categories?: ICategoryBrief[];
}

function parsePage(input: string | null) {
  if (input == null) {
    return 1;
  }

  const num = Number(input);

  if (Number.isNaN(num) || num < 1) {
    return 1;
  }

  return num;
}

export function CatalogPage() {
  const device = useRecoilValue(deviceAtom);
  const [state, updateState] = useImmer<IState>({
    page: parsePage(AddressBarService.getInstance().getQueryParam('page')),
    categoryPermalink: AddressBarService.getInstance().getQueryParam('category') ?? undefined,
    filter: AddressBarService.getInstance().getQueryParam('filter') ?? undefined,
  });

  useEffect(() => {
    loadCategories();
    loadProducts();
  }, []); // eslint-disable-line

  const loadCategories = async () => {
    const categories = await ApiCategoryService.getInstance().getAll();
    updateState(x => {
      x.categories = categories;
    });
  };

  const loadProducts = async () => {
    const params =
      state.categoryPermalink != null || state.filter != null
        ? { filter: state.filter, category: state.categoryPermalink, withCategoryChildren: true }
        : undefined;

    const productSearch = await ApiProductsService.getInstance().search(state.page, params);
    updateState(x => {
      x.productSearch = productSearch;
    });
  };

  let title = state.filter != null ? 'Поиск товаров' : 'Список товаров';

  if (state.categories == null || state.productSearch == null) {
    return (
      <Page title={title}>
        <ModalLoader />
      </Page>
    );
  }

  let frameTitle = 'СПИСОК ТОВАРОВ';
  let category: ICategoryBrief | undefined;
  let description: string | undefined = undefined;

  if (state.filter != null) {
    frameTitle = `ПОИСК ТОВАРОВ «${state.filter}»`;
    title = `Поиск «${state.filter}»`;
    description = `Поиск товаров в магазине Гидравлика75 по запросу «${state.filter}» - найдено ${state.productSearch.count} товаров в Чите. Страница ${state.page}.`;
  } else if (state.categoryPermalink) {
    const foundCategory = state.categories.find(x => x.permalink === state.categoryPermalink);

    if (foundCategory != null) {
      category = foundCategory;
    }

    title = `Товары в категории «${foundCategory?.title ?? state.categoryPermalink}»`;
    description = `Купить товары в категории «${foundCategory?.title ?? state.categoryPermalink}», всего найдено ${
      state.productSearch.count
    } объектов в Чите на странице ${state.page} в магазине Гидравлика75`;
    frameTitle = `КАТЕГОРИЯ «${foundCategory?.title ?? state.categoryPermalink}»`;
  }

  const frame =
    device === 'desktop' ? (
      <DesktopCatalog
        page={state.page}
        maxPage={Math.ceil(state.productSearch.count / 30)}
        title={frameTitle}
        categories={state.categories}
        products={state.productSearch}
      />
    ) : (
      <MobileCatalog
        page={state.page}
        maxPage={Math.ceil(state.productSearch.count / 30)}
        title={frameTitle}
        category={category}
        categories={state.categories}
        products={state.productSearch}
      />
    );

  return (
    <Page
      title={title}
      description={description}
    >
      {frame}
    </Page>
  );
}
