import { ApiClient } from 'common/api';
import { IProduct, IProductSearch } from 'common/models';

enum ProductsNodes {
  Products = '/products',
}

export class ApiProductsService {
  private static instance: ApiProductsService | null = null;

  private apiService: ApiClient;

  private constructor() {
    this.apiService = ApiClient.getInstance();
  }

  static getInstance() {
    if (this.instance == null) {
      this.instance = new ApiProductsService();
    }

    return this.instance;
  }

  async getById(productId: string): Promise<IProduct> {
    const address = `${ProductsNodes.Products}/${productId}`;
    const product = await this.apiService.sendRequest<IProduct>('get', address);
    return product;
  }

  async getByPermalink(productPermalink: string): Promise<IProduct> {
    const address = `${ProductsNodes.Products}/${productPermalink}`;
    const product = await this.apiService.sendRequest<IProduct>('get', address);
    return product;
  }

  async search(
    page: number,
    options?: {
      filter?: string;
      category?: string;
      withCategoryChildren?: boolean;
      random?: boolean;
    }
  ): Promise<IProductSearch> {
    let address = `${ProductsNodes.Products}/${page}`;

    if (options != null) {
      const params = new URLSearchParams();

      if (options.filter != null) {
        params.append('filter', options.filter);
      }

      if (options.category != null) {
        params.append('category', options.category);
      }

      if (options.withCategoryChildren != null) {
        params.append('withCategoryChildren', options.withCategoryChildren.toString());
      }

      if (options.random != null) {
        params.append('random', options.random.toString());
      }

      address = `${address}?${params.toString()}`;
    }

    const products = await this.apiService.sendRequest<IProductSearch>('get', address);
    return products;
  }
}
