import { ApiClient } from 'common/api';
import { hshopUrls } from 'common/consts';

enum ImageNodes {
  Images = '/images',
}

export class ApiImageService {
  private static instance: ApiImageService | null = null;

  private apiService: ApiClient;

  private constructor() {
    this.apiService = ApiClient.getInstance();
  }

  static getInstance() {
    if (this.instance == null) {
      this.instance = new ApiImageService();
    }

    return this.instance;
  }

  getUrl(id: string): string {
    return hshopUrls.api + ImageNodes.Images + '/' + id;
  }
}
