import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { deviceAtom } from 'common/recoil';
import { Link, Stack } from 'components';
import { LinkButton, LinkButtonMobile, Logo, SearchBar } from './components';
import './style-desktop.css';
import './style-mobile.css';

function MobileHeader() {
  const [open, setOpen] = useState(false);

  const headerComponents: React.ReactNode[] = [];

  headerComponents.push(
    <Stack
      key={0}
      className='header-mobile-Nshd'
      orientation='horizontal'
      contentFill={[0, 1, 0]}
    >
      <div
        tabIndex={0}
        role='button'
        className='open-bar-button-12tB'
        onClick={() => setOpen(!open)}
      >
        {open ? <i className='bi bi-arrow-up' /> : <i className='bi bi-list' />}
      </div>
      <Stack
        orientation='horizontal'
        contentFill={[1, 0, 1]}
        style={{ alignItems: 'center' }}
        fill
      >
        <div />
        <Logo />
        <div />
      </Stack>
      <Link
        className='cart-mobile-hsus'
        href='/cart'
      >
        <i className='bi bi-cart4'></i>
      </Link>
    </Stack>
  );

  if (open) {
    headerComponents.push(
      <Stack
        key={1}
        gap={20}
        orientation='vertical'
        className='header-wrapper-menu-NShd'
      >
        <SearchBar />
        <Stack
          orientation='vertical'
          gap={10}
        >
          <LinkButtonMobile
            href='/'
            icon={<i className='bi bi-house'></i>}
            title='Главная страница'
          />
          <LinkButtonMobile
            href='/products'
            icon={<i className='bi bi-book'></i>}
            title='Каталог товаров'
          />
          <LinkButtonMobile
            href='/shipping'
            icon={<i className='bi bi-box-seam'></i>}
            title='О доставке'
          />
          <LinkButtonMobile
            href='/about'
            icon={<i className='bi bi-question-circle'></i>}
            title='О компании'
          />
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      orientation='vertical'
      className='header-mobile-container-1sAY'
    >
      {headerComponents}
    </Stack>
  );
}

function DesktopHeader() {
  return (
    <Stack
      orientation='horizontal'
      className='header-container-zSJ'
      contentFill={[1, 0]}
    >
      <Stack
        className='header-Ns12'
        orientation='horizontal'
        contentFill={[0, 1, '280px']}
        gap={40}
      >
        <Logo />
        <Stack
          className='header-links-ZNshd'
          orientation='horizontal'
          gap={20}
        >
          <LinkButton
            orientation='vertical'
            href='/'
            icon={<i className='bi bi-house'></i>}
            title='Главная'
          />
          <LinkButton
            orientation='vertical'
            href='/products'
            icon={<i className='bi bi-book'></i>}
            title='Каталог'
          />
          <LinkButton
            orientation='vertical'
            href='/shipping'
            icon={<i className='bi bi-box-seam'></i>}
            title='Доставка'
          />
          <LinkButton
            orientation='vertical'
            href='/about'
            icon={<i className='bi bi-question-circle'></i>}
            title='О нас'
          />
          <div />
        </Stack>
        <SearchBar />
      </Stack>
      <Link
        className='cart-hsus'
        href='/cart'
      >
        <Stack
          className='cart-content-JSy'
          orientation='vertical'
          gap={5}
          fill
        >
          <div>
            <div className='cart-icon-Sjdh'>
              <i className='bi bi-cart4'></i>
            </div>
            <div>Корзина</div>
          </div>
        </Stack>
      </Link>
    </Stack>
  );
}

export function Header() {
  const device = useRecoilValue(deviceAtom);
  return device === 'mobile' ? <MobileHeader /> : <DesktopHeader />;
}
