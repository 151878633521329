export class Guid {
  public static isEmpty(input: Guid | string) {
    if (input instanceof Guid) {
      throw new Error();
    }

    return input === this.empty();
  }

  public static empty(): string {
    return '00000000-0000-0000-0000-000000000000';
  }
}
