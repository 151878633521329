import { MessageCard, Stack } from 'components';

export function PaymentCard() {
  return (
    <MessageCard title='СПОСОБЫ ОПЛАТЫ'>
      <Stack
        orientation='vertical'
        gap={5}
      >
        <h3>НАЛОЖЕННЫЙ ПЛАТЕЖ</h3>
        <br />
        <p>
          Оплата наложенным платежом, в данном случае вы оплачиваете посылку при её получении на Почте России или в транспортной
          компании СДЭК.
        </p>
        <p>В стоимость включено:</p>
        <ul>
          <li>Услуги по отправке и доставке;</li>
          <li>Стоимость заказа.</li>
        </ul>
        <br></br>
        <h3>БЕЗНАЛИЧНАЯ ОПЛАТА</h3>
        <br />
        <p>Полная оплата заказа и доставки перед отправкой.</p>
        <p>Подробности вы можете узнать у нашего менеджера после оформления заказа.</p>
      </Stack>
    </MessageCard>
  );
}
