import { Link, Stack } from 'components';
import './style.css';

export function Logo() {
  return (
    <Link
      className='logo-dsax'
      href='/'
    >
      <Stack
        orientation='horizontal'
        gap={5}
      >
        <div className='logo-text-12HS'>ГИДРАВЛИКА</div>
        <div className='logo-region-NZsh'>75</div>
      </Stack>
    </Link>
  );
}
