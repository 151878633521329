import { Stack } from 'components';
import './style.css';

export function Footer() {
  return (
    <footer>
      <Stack
        orientation='vertical'
        className='footer-NdhsYs'
      >
        <Stack
          orientation='horizontal'
          gap={40}
        >
          <Stack
            className='footer-block-NAhdy'
            orientation='vertical'
            gap={20}
          >
            <div className='footer-title-NSdhs'>ИНФОРМАЦИЯ</div>
            <Stack
              orientation='vertical'
              gap={5}
            >
              <a href='/'>Главная</a>
              <a href='/shipping'>О доставке</a>
              <a href='/about'>О компании</a>
            </Stack>
          </Stack>
          <Stack
            className='footer-block-NAhdy'
            orientation='vertical'
            gap={20}
          >
            <div className='footer-title-NSdhs'>КОНТАКТЫ</div>
            <Stack
              orientation='horizontal'
              gap={40}
            >
              <Stack
                orientation='vertical'
                gap={10}
              >
                <Stack
                  orientation='vertical'
                  gap={5}
                >
                  <a href='tel:7-914-350-11-15'>+7 (914) 350-11-15</a>
                  <a href='mailto:hydravlyka75@mail.ru'>hydravlyka75@mail.ru</a>
                  <a href='https://go.2gis.com/hhn42'>г. Чита, ул. Инструментальная 7</a>
                </Stack>
                <Stack
                  orientation='vertical'
                  gap={5}
                >
                  <Stack
                    orientation='horizontal'
                    gap={5}
                  >
                    <i className='bi bi-whatsapp'></i>
                    <a href='https://wa.me/79143501115'>+7 (914) 350-11-15</a>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </footer>
  );
}
