import { ApiCategoryService } from 'common/api';
import { ICategoryBrief } from 'common/models';
import { deviceAtom } from 'common/recoil';
import { Page, ModalLoader } from 'components';
import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { DesktopAbout, MobileAbout } from './frames';

export function AboutPage() {
  const device = useRecoilValue(deviceAtom);
  const [categories, setCategories] = useState<ICategoryBrief[] | null>(null);
  useEffect(() => {
    loadCategories();
  });

  const loadCategories = async () => {
    const categoryService = ApiCategoryService.getInstance();
    const categories = await categoryService.getAll();
    setCategories(categories);
  };

  const description = 'Полезная информация о нашем магазине. Контакты и адреса интернет-магазина Гидравлика75!';

  if (categories == null) {
    return (
      <Page
        title='О компании'
        description={description}
      >
        <ModalLoader />
      </Page>
    );
  }

  const frame = device === 'mobile' ? <MobileAbout /> : <DesktopAbout categories={categories} />;

  return (
    <Page
      title='О компании'
      description={description}
    >
      {frame}
    </Page>
  );
}
