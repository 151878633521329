import { Stack } from 'components';
import './style.css';

interface IProps {
  name: string;
  text: string;
  rating: number;
  maxRating: number;
  date: string;
}

function twoDigitString(input: number) {
  const result = input.toString();
  return result.length === 1 ? '0' + result : result;
}

export function Review(props: IProps) {
  const starCountFractional = (props.rating / props.maxRating) * 5;
  const starCount =
    starCountFractional % 1 < 0.5
      ? Math.floor(starCountFractional)
      : Math.ceil(starCountFractional);

  const stars: React.ReactNode[] = [];

  for (let i = 0; i < starCount; i++) {
    stars.push(
      <i
        key={i}
        className='bi bi-star-fill'
      />
    );
  }

  for (let i = stars.length - 1; i < starCount; i++) {
    stars.push(
      <i
        key={i}
        className='bi bi-star'
      />
    );
  }

  const reviewDate = new Date(props.date);
  const date = `${twoDigitString(reviewDate.getDate())}.${twoDigitString(
    reviewDate.getMonth() + 1
  )}.${twoDigitString(reviewDate.getFullYear())}`;

  return (
    <Stack
      orientation='vertical'
      className='review-h4sus'
      gap={10}
    >
      <Stack
        orientation='horizontal'
        className='user-row-NSdh'
        contentFill={[0, 1, 0]}
      >
        <Stack
          orientation='horizontal'
          gap={5}
        >
          <span>{props.name}</span>
        </Stack>
        <div />
        <Stack orientation='horizontal'>{stars}</Stack>
      </Stack>
      <i>{props.text}</i>
      <div className='review-date-SJdu'>
        <i>{date}</i>
      </div>
    </Stack>
  );
}
