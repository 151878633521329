import { NavigateService } from 'common/service';
import { Link, Stack } from 'components';
import './style.css';
import { useRecoilValue } from 'recoil';
import { deviceAtom } from 'common/recoil';

interface IProps {
  page: number;
  maxPage: number;
}

function PageButton(props: { page: number; isSelected: boolean; content: React.ReactNode; disabled?: boolean }) {
  return (
    <Link
      className='page-button-J43hs'
      href={NavigateService.getInstance().productsPageUrl({ page: props.page })}
      disabled={props.isSelected || props.disabled}
    >
      {props.content}
    </Link>
  );
}

export function Pagination(props: IProps) {
  const device = useRecoilValue(deviceAtom);

  const pageDiff = device === 'desktop' ? 2 : 1;

  const minPage = props.page - pageDiff <= 1 ? 1 : props.page - pageDiff;
  const maxPage = props.page + pageDiff >= props.maxPage ? props.maxPage : props.page + pageDiff;

  const showFirst = minPage > 1;
  const showLast = maxPage < props.maxPage;

  const pageButtons: React.ReactNode[] = [];

  pageButtons.push(
    <PageButton
      disabled={props.page === 1}
      isSelected={false}
      page={props.page - 1}
      key={-1}
      content={'<'}
    />
  );

  if (showFirst) {
    pageButtons.push(
      <PageButton
        isSelected={props.page === 1}
        page={1}
        key={1}
        content={1}
      />
    );
  }

  if (minPage - 1 > 1 && device === 'desktop') {
    pageButtons.push(
      <div
        key={2}
        className='page-filler-jSu'
      >
        —
      </div>
    );
  }

  for (let i = minPage; i <= maxPage; i++) {
    pageButtons.push(
      <PageButton
        isSelected={props.page === i}
        page={i}
        key={i}
        content={i}
      />
    );
  }

  if (maxPage + 1 < props.maxPage && device === 'desktop') {
    pageButtons.push(
      <div
        key={props.maxPage}
        className='page-filler-jSu'
      >
        —
      </div>
    );
  }

  if (showLast) {
    pageButtons.push(
      <PageButton
        page={props.maxPage}
        isSelected={props.page === props.maxPage}
        key={props.maxPage}
        content={props.maxPage}
      />
    );
  }

  pageButtons.push(
    <PageButton
      disabled={props.page === props.maxPage}
      isSelected={false}
      page={props.page + 1}
      key={props.maxPage + 1}
      content={'>'}
    />
  );

  return (
    <Stack
      contentFill={[1, 0, 1]}
      orientation='horizontal'
      fill
    >
      <div />
      <Stack
        orientation='horizontal'
        gap={10}
      >
        {pageButtons}
      </Stack>
      <div />
    </Stack>
  );
}
