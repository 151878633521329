import { Stack } from 'components';
import './style.css';

export function NoProducts() {
  return (
    <Stack
      orientation='horizontal'
      contentFill={[1, 0, 1]}
    >
      <div />
      <Stack
        orientation='vertical'
        className='no-products-J3hs'
        gap={10}
      >
        <i className='bi bi-search no-products-icon-3jy' />
        <div>Товары не найдены</div>
      </Stack>
      <div />
    </Stack>
  );
}
