import { ICategoryBrief, IProduct } from 'common/models';
import { ICartProductFull } from 'common/types';
import { Stack } from 'components';
import { ProductList } from 'shared/components';
import { CartProductList, CreateOrderBlock, AboutOrderBlock } from '../components';

interface IProps {
  categories: ICategoryBrief[];
  cartProducts: ICartProductFull[];
  products: IProduct[];

  onIncreaseClick: (product: IProduct) => void;
  onDecreaseClick: (product: IProduct) => void;
}

export function MobileCart(props: IProps) {
  return (
    <Stack
      orientation='vertical'
      className='page-content-NAjSy'
      gap={20}
    >
      <CartProductList
        cartProducts={props.cartProducts}
        onDecreaseClick={props.onDecreaseClick}
        onIncreaseClick={props.onIncreaseClick}
      />

      <CreateOrderBlock products={props.cartProducts} />
      <AboutOrderBlock />

      <Stack
        orientation='vertical'
        gap={10}
      >
        <h2>ДРУГИЕ ТОВАРЫ</h2>
        <ProductList
          products={props.products}
          hideUnfilled
          maxRows={4}
        />
      </Stack>
    </Stack>
  );
}
