import { Stack } from 'components';
import { ShippingCard, PaymentCard, AdditionInfoCard } from 'pages/shipping-page/components';

export function MobileShipping() {
  return (
    <Stack
      orientation='vertical'
      className='page-content-NAjSy'
      gap={10}
    >
      <h1>ДОСТАВКА</h1>
      <ShippingCard />
      <PaymentCard />
      <AdditionInfoCard />
    </Stack>
  );
}
