import { ICategoryBrief, IProduct } from 'common/models';
import { Stack } from 'components';
import { CategoriesBlock, ProductList } from 'shared/components';
import {
  ButtonAddToCart,
  ButtonBuyNow,
  ProductAmount,
  ProductDescription,
  ProductImages,
  ProductPrice,
  ProductTitle,
} from '../components';

interface IProps {
  categories: ICategoryBrief[];
  product: IProduct;
  products: IProduct[];
}

export function DesktopProduct(props: IProps) {
  return (
    <Stack
      orientation='horizontal'
      gap={20}
      contentFill={['300px', 1]}
      className='page-content-NAjSy'
    >
      <CategoriesBlock
        isMobile={false}
        categories={props.categories}
      />
      <Stack
        orientation='horizontal'
        contentFill={[1, 0]}
      >
        <Stack
          orientation='vertical'
          gap={40}
        >
          <Stack
            orientation='horizontal'
            contentFill={['400px', '500px']}
            gap={10}
          >
            <ProductImages product={props.product} />
            <Stack
              orientation='vertical'
              gap={40}
            >
              <ProductTitle product={props.product} />
              <Stack
                orientation='horizontal'
                gap={10}
                contentFill={['220px', 1]}
              >
                <Stack
                  orientation='vertical'
                  gap={5}
                >
                  <ProductPrice product={props.product} />
                  <ProductAmount product={props.product} />
                </Stack>
                <Stack
                  orientation='vertical'
                  gap={10}
                >
                  <ButtonAddToCart product={props.product} />
                  <ButtonBuyNow product={props.product} />
                </Stack>
              </Stack>
              <ProductDescription product={props.product} />
            </Stack>
          </Stack>
          <Stack
            orientation='vertical'
            gap={10}
          >
            <h2>ПОСМОТРИТЕ И ДРУГИЕ ТОВАРЫ</h2>
            <ProductList
              maxRows={2}
              products={props.products}
              hideUnfilled
            />
          </Stack>
        </Stack>

        <div className='content-filler-nAJsy' />
      </Stack>
    </Stack>
  );
}
