import { AddressBarService } from 'common/service';

export class NavigateService {
  private static instance: NavigateService | null = null;
  private addressBarService: AddressBarService;

  private constructor() {
    this.addressBarService = AddressBarService.getInstance();
  }

  static getInstance() {
    if (this.instance == null) {
      this.instance = new NavigateService();
    }

    return this.instance;
  }

  productPageUrl(productId: string): string {
    return `/products/${productId}`;
  }

  productsPageUrl(params: { page?: number; filter?: string; category?: string }, absolute = false): string {
    const parts = this.addressBarService.currentUrlParts;
    const relative = !absolute && parts.length === 1 && parts[0] === 'products';

    var urlParams = new URLSearchParams();

    if (params.page != null) {
      urlParams.append('page', params.page.toString());
    } else if (relative) {
      const oldParam = this.addressBarService.getQueryParam('page');

      if (oldParam != null) {
        urlParams.append('page', oldParam);
      }
    }

    if (params.filter != null) {
      urlParams.append('filter', params.filter.toString());
    } else if (relative) {
      const oldParam = this.addressBarService.getQueryParam('filter');

      if (oldParam != null) {
        urlParams.append('filter', oldParam);
      }
    }

    if (params.category != null) {
      urlParams.append('category', params.category.toString());
    } else if (relative) {
      const oldParam = this.addressBarService.getQueryParam('category');

      if (oldParam != null) {
        urlParams.append('category', oldParam);
      }
    }

    return `/products?${urlParams.toString()}`;
  }

  productsPage(params: { page?: number; filter?: string; category?: string }, absolute = true) {
    const href = this.productsPageUrl(params, absolute);
    window.location.href = href;
  }
}
