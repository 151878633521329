import { ICategoryBrief } from 'common/models';
import { Label, Stack } from 'components';
import { useRef, useState } from 'react';
import { ApiImageService } from 'common/api';
import { Guid } from 'common/types';
import './style-desktop.css';
import './style-mobile.css';
import './style.css';

function CategoryButtonMobile(props: { category: ICategoryBrief }) {
  const backroundImageUrl =
    props.category.imageId !== Guid.empty() ? `url(${ApiImageService.getInstance().getUrl(props.category.imageId)})` : undefined;

  return (
    <div
      className='category-button-ksH'
      role='button'
      tabIndex={0}
    >
      <div className='background-container-HDsy'>
        <div
          className='background-dasj'
          style={{ backgroundImage: backroundImageUrl }}
        />
      </div>
      <div className='content-Jsdsy mobile-MAjd'>
        <a href={`/products?category=${props.category.permalink}`}>
          <Label>{props.category.title}</Label>
        </a>
      </div>
    </div>
  );
}

function CategoryButtonDesktop(props: {
  category: ICategoryBrief;
  categories: ICategoryBrief[];
  direction?: 'right' | 'down';
  positon?: {
    x: number;
    y: number;
  };
  style?: React.CSSProperties;
}) {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const childs = props.categories.filter(x => x.parentId === props.category.id);

  const handleMouseEnter = () => {
    if (childs.length === 0) {
      return;
    }

    setOpen(true);
  };

  const handleMouseLeave = () => {
    if (childs.length === 0) {
      return;
    }

    setOpen(false);
  };

  const content =
    childs.length > 0 ? (
      <Stack
        orientation='horizontal'
        contentFill={[1, 0]}
        fill
      >
        <Label>{props.category.title}</Label>
        <i className='bi bi-chevron-right'></i>
      </Stack>
    ) : (
      <Label>{props.category.title}</Label>
    );

  const offsetLeft = () => {
    if (props.direction === 'down') {
      return ref.current?.offsetLeft;
    }

    return (ref.current?.clientWidth || 0) + (ref.current?.offsetLeft || 0);
  };

  const offsetTop = () => {
    if (props.direction == null || props.direction === 'right') {
      return ref.current?.offsetTop;
    }

    return (ref.current?.clientHeight || 0) + (ref.current?.offsetTop || 0);
  };

  const backroundImageUrl =
    props.category.imageId !== Guid.empty() ? `url(${ApiImageService.getInstance().getUrl(props.category.imageId)})` : undefined;

  return (
    <div
      className='category-button-ksH'
      role='button'
      tabIndex={0}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={props.style}
    >
      <div className='background-container-HDsy'>
        <div
          className='background-dasj'
          style={{ backgroundImage: backroundImageUrl }}
        />
      </div>
      <div
        ref={ref}
        className='content-Jsdsy'
      >
        <a href={`/products?category=${props.category.permalink}`}>{content}</a>
      </div>
      {isOpen && (
        <Stack
          orientation='vertical'
          gap={5}
          style={{
            padding: '0 5px',
            position: 'absolute',
            left: `${offsetLeft()}px`,
            top: `${offsetTop()}px`,
            width: '250px',
            zIndex: 2,
          }}
        >
          {childs.map(x => (
            <CategoryButtonDesktop
              key={x.id}
              category={x}
              categories={props.categories}
              style={{
                boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, .5)',
              }}
            />
          ))}
        </Stack>
      )}
    </div>
  );
}

export function CategoryButton(props: {
  category: ICategoryBrief;
  categories: ICategoryBrief[];
  isMobile: boolean;
  direction?: 'right' | 'down';
}) {
  return props.isMobile ? (
    <CategoryButtonMobile category={props.category} />
  ) : (
    <CategoryButtonDesktop
      direction={props.direction}
      categories={props.categories}
      category={props.category}
    />
  );
}
