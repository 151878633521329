import { ForwardedRef, forwardRef } from 'react';
import { IPropsContainer } from 'common/types';
import './style.css';

interface IButtonProps extends IPropsContainer {
  href?: string;
  newTab?: boolean;
  onClick?: () => void;
  onEnter?: () => void;
  onLeave?: () => void;
}

function Button(props: IButtonProps, ref: ForwardedRef<HTMLElement>) {
  let className = 'button-NZhf';

  if (props.className != null) {
    className += ` ${props.className}`;
  }

  const handleClick = () => {
    if (props.onClick != null) {
      props.onClick();
    }
  };

  const handleEnter = () => {
    if (props.onEnter != null) {
      props.onEnter();
    }
  };

  const handleLeave = () => {
    if (props.onLeave != null) {
      props.onLeave();
    }
  };

  if (props.href == null) {
    return (
      <div
        ref={ref as any}
        role='button'
        tabIndex={0}
        className={className}
        style={props.style}
        onClick={handleClick}
        onMouseEnter={handleEnter}
        onMouseLeave={handleLeave}
      >
        {props.children}
      </div>
    );
  }

  return (
    <a
      ref={ref as any}
      style={props.style}
      className='link-butt0n-Jdya'
      href={props.href}
      target={props.newTab ? '_blank' : undefined}
      rel={props.newTab ? 'noopener noreferrer' : undefined}
    >
      <div className={className}>{props.children}</div>
    </a>
  );
}

export default forwardRef(Button);
