import { ICategoryBrief } from 'common/models';
import { ApiClient } from 'common/api';

enum CategoryNodes {
  Categories = '/categories',
}

export class ApiCategoryService {
  private static instance: ApiCategoryService | null = null;

  private apiService: ApiClient;

  private constructor() {
    this.apiService = ApiClient.getInstance();
  }

  static getInstance() {
    if (this.instance == null) {
      this.instance = new ApiCategoryService();
    }

    return this.instance;
  }

  async getAll(): Promise<ICategoryBrief[]> {
    const response = await this.apiService.sendRequest<ICategoryBrief[]>('get', CategoryNodes.Categories);
    return response;
  }
}
