import { Stack } from 'components';
import './style.css';

interface IProps {
  title?: string;
  children?: React.ReactNode | React.ReactNode[];
}

export function MessageCard(props: IProps) {
  if (props.title == null) {
    return <div className='card-message-sNhrs'>{props.children}</div>;
  }

  return (
    <Stack
      orientation='vertical'
      gap={20}
      className='card-message-sNhrs'
    >
      <h2 className='message-header-Nshd'>{props.title}</h2>
      {props.children}
    </Stack>
  );
}
