import { ICategoryBrief, IProduct } from 'common/models';
import { ICartProductFull } from 'common/types';
import { Stack } from 'components';
import { CategoriesBlock, ProductList } from 'shared/components';
import { AboutOrderBlock, CartProductList, CreateOrderBlock } from '../components';

interface IProps {
  categories: ICategoryBrief[];
  cartProducts: ICartProductFull[];
  products: IProduct[];

  onIncreaseClick: (product: IProduct) => void;
  onDecreaseClick: (product: IProduct) => void;
}

export function DesktopCart(props: IProps) {
  return (
    <Stack
      className='page-content-NAjSy'
      orientation='horizontal'
      contentFill={['300px', 1]}
      gap={20}
    >
      <CategoriesBlock
        categories={props.categories}
        isMobile={false}
      />
      <Stack
        orientation='horizontal'
        contentFill={[1, 0]}
      >
        <Stack
          orientation='vertical'
          gap={20}
        >
          <CartProductList
            cartProducts={props.cartProducts}
            onDecreaseClick={props.onDecreaseClick}
            onIncreaseClick={props.onIncreaseClick}
          />

          <Stack
            orientation='horizontal'
            gap={10}
            contentFill={[1, '300px']}
          >
            <CreateOrderBlock products={props.cartProducts} />
            <AboutOrderBlock />
          </Stack>

          <Stack
            orientation='vertical'
            gap={10}
          >
            <h2>ДРУГИЕ ТОВАРЫ</h2>
            <ProductList
              products={props.products}
              hideUnfilled
              maxRows={1}
            />
          </Stack>
        </Stack>
        <div className='content-filler-nAJsy'></div>
      </Stack>
    </Stack>
  );
}
