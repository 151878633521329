import { ICategoryBrief } from 'common/models';
import { Stack } from 'components';
import { ShippingCard, PaymentCard, AdditionInfoCard } from 'pages/shipping-page/components';
import { CategoriesBlock } from 'shared/components';

export function DesktopShipping(props: { categories: ICategoryBrief[] }) {
  return (
    <Stack
      orientation='vertical'
      gap={20}
    >
      <Stack
        className='page-content-NAjSy'
        orientation='horizontal'
        gap={20}
        contentFill={['300px', 1]}
      >
        <CategoriesBlock categories={props.categories} />

        <Stack
          orientation='horizontal'
          contentFill={[1, 0]}
        >
          <Stack
            orientation='vertical'
            gap={10}
          >
            <h1>ДОСТАВКА</h1>
            <ShippingCard />
            <PaymentCard />
            <AdditionInfoCard />
          </Stack>
          <div className='content-filler-nAJsy'></div>
        </Stack>
      </Stack>
    </Stack>
  );
}
