import { ModalLoader, Page } from 'components';
import { DesktopProduct } from './desktop/DesktopProduct';
import { ICategoryBrief, IProduct } from 'common/models';
import { useImmer } from 'use-immer';
import { ApiCategoryService, ApiProductsService } from 'common/api';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { deviceAtom } from 'common/recoil';
import { MobileProduct } from './mobile/MobileProduct';
import { ApiError } from 'common/errors';

interface IState {
  categories?: ICategoryBrief[];
  product?: IProduct;
  products?: IProduct[];
}

export function ProductPage() {
  const [state, updateState] = useImmer<IState>({});
  const { productPermalink } = useParams();
  const device = useRecoilValue(deviceAtom);

  useEffect(() => {
    loadCategories();
    loadProducts();
    loadProduct();
  }, []); // eslint-disable-line

  const loadCategories = async () => {
    const categoriesService = ApiCategoryService.getInstance();
    const categories = await categoriesService.getAll();
    updateState(x => {
      x.categories = categories;
    });
  };

  const loadProduct = async () => {
    if (productPermalink == null) {
      throw new Error();
    }

    const productsService = ApiProductsService.getInstance();

    try {
      const product = await productsService.getByPermalink(productPermalink);

      updateState(x => {
        x.product = product;
      });
    } catch (e) {
      if (e instanceof ApiError) {
        window.location.href = `/error/${e.code}`;
      } else {
        window.location.href = `/error`;
      }
    }
  };

  const loadProducts = async () => {
    if (productPermalink == null) {
      throw new Error();
    }

    const productsService = ApiProductsService.getInstance();
    const products = await productsService.search(1, { random: true });

    updateState(x => {
      x.products = products.result;
    });
  };

  if (state.categories == null || state.product == null || state.products == null) {
    return (
      <Page title='Просмотр товара'>
        <ModalLoader />
      </Page>
    );
  }

  const description =
    state.product.description == null
      ? `${state.product.title} в городе Чита. Купить за ${state.product.price} Рублей, осталось ${state.product.quantity} ${state.product.measureUnit}. Купить сейчас или под заказ.`
      : `${state.product.title} — ${state.product.description} в городе Чита. Купить за ${state.product.price} Рублей, осталось ${state.product.quantity} ${state.product.measureUnit}. Купить сейчас или под заказ.`;

  const content =
    device === 'desktop' ? (
      <DesktopProduct
        categories={state.categories}
        product={state.product}
        products={state.products}
      />
    ) : (
      <MobileProduct
        categories={state.categories}
        product={state.product}
        products={state.products}
      />
    );

  return (
    <Page
      title={`${state.product.title} купить в Чите`}
      description={description}
    >
      {content}
    </Page>
  );
}
