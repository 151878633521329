import { useEffect } from 'react';
import { useImmer } from 'use-immer';
import { useRecoilValue } from 'recoil';
import { deviceAtom } from 'common/recoil';
import { ICategoryBrief, IProduct, IReview } from 'common/models';
import { ApiCategoryService, ApiProductsService, ApiReviewService } from 'common/api';
import { Link, ModalLoader, Page, Stack } from 'components';
import { CategoriesBlock, ProductList } from 'shared/components';
import { Review } from './components';
import { WhyWeCard } from 'shared/components';
import './style.css';

function ProductsBlock(props: { products: IProduct[] }) {
  return (
    <Stack
      orientation='vertical'
      gap={10}
      contentFill={[0, 1]}
    >
      <Link
        className='title-Jsud'
        href={`/products`}
      >
        <Stack
          orientation='horizontal'
          gap={5}
        >
          <h1>ПОПУЛЯРНЫЕ ТОВАРЫ</h1>
          <div className='title-chevron-sjdhSu'>
            <i className='bi bi-chevron-right'></i>
          </div>
        </Stack>
      </Link>
      <ProductList
        products={props.products}
        hideUnfilled
      />
    </Stack>
  );
}

function LeftBar(props: { categories: ICategoryBrief[]; isMobile: boolean }) {
  return (
    <CategoriesBlock
      categories={props.categories}
      isMobile={props.isMobile}
    />
  );
}

interface IState {
  categories?: ICategoryBrief[];
  products?: IProduct[];
  review?: IReview;
}

export function MainPage() {
  const [state, updateState] = useImmer<IState>({});
  const device = useRecoilValue(deviceAtom);

  useEffect(() => {
    loadCategories();
    loadProducts();
    loadReview();
  }, []); // eslint-disable-line

  const loadReview = async () => {
    const reviewService = ApiReviewService.getInstance();

    try {
      const review = await reviewService.get();
      updateState(x => {
        x.review = review;
      });
    } catch {}
  };

  const loadProducts = async () => {
    const productsService = ApiProductsService.getInstance();
    const productSearch = await productsService.search(1, { random: true });
    updateState(x => {
      x.products = productSearch.result;
    });
  };

  const loadCategories = async () => {
    const categoryService = ApiCategoryService.getInstance();
    const categories = await categoryService.getAll();
    updateState(x => {
      x.categories = categories;
    });
  };

  const reviewBlock =
    state.review != null ? (
      <Review
        name={state.review.nameAuthor}
        text={state.review.text}
        date={state.review.date}
        rating={state.review.realRating}
        maxRating={state.review.maxRating}
      />
    ) : (
      <div />
    );

  const content =
    device === 'mobile' ? (
      <Stack
        className='page-content-NAjSy'
        orientation='vertical'
        gap={20}
      >
        <CategoriesBlock
          categories={state.categories || []}
          isMobile={device === 'mobile'}
        />
        {reviewBlock}
        <ProductsBlock products={state.products || []} />
        <WhyWeCard />
      </Stack>
    ) : (
      <Stack
        orientation='vertical'
        gap={20}
      >
        <Stack
          className='page-content-NAjSy'
          orientation='horizontal'
          gap={20}
          contentFill={['300px', 1]}
        >
          <LeftBar
            categories={state.categories || []}
            isMobile={device !== 'desktop'}
          />

          <Stack
            orientation='horizontal'
            contentFill={[1, 0]}
          >
            <Stack
              orientation='vertical'
              gap={20}
            >
              <ProductsBlock products={state.products || []} />
              <WhyWeCard />
            </Stack>
            <Stack
              orientation='vertical'
              className='content-filler-nAJsy'
              gap={20}
            >
              <Stack
                orientation='vertical'
                gap={10}
              >
                <h1 className='title-Jsud'>ОТЗЫВ ДНЯ</h1>
                {reviewBlock}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );

  const description =
    'Магазин гидравлических уплотнений, насосов, распределителей и других товаров в городе Чита. У нас есть доставка по всей России и странам СНГ!';

  return (
    <Page
      title='Купить манжеты для гидравлики в Чите'
      description={description}
    >
      {(state.categories == null || state.products == null) && <ModalLoader />}
      {state.categories != null && content}
    </Page>
  );
}
