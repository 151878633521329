import { MessageCard, Stack } from 'components';

export function AboutCard() {
  return (
    <MessageCard title='ОБЩАЯ ИНФОРМАЦИЯ'>
      <Stack
        orientation='vertical'
        gap={5}
      >
        <p>
          Добро пожаловать на сайт нашего магазина <b>«Гидравлика75»</b>!
        </p>
        <p>Мы рады предложить вам широкий выбор уплотнений для ремонта гидроцилиндров и другого гидравлического оборудования.</p>
        <p>Так же у нас в наличии имеется широкий выбор гидравлеческих насосов и распределителей.</p>
        <p>
          Гарантируем высокое качество предлагаемой продукции, а также оперативную <a href='/shipping'>доставку</a> по всей стране
          и за её пределы.
        </p>
        <br />
        <p>
          А ещё у нас есть опытные специалисты, которые помогут выполнить качественный ремонт гидроцилиндров и другого
          гидравлического оборудования.
        </p>
      </Stack>
    </MessageCard>
  );
}
