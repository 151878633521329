import { IProduct } from 'common/models';
import './style.css';

interface IProps {
  product: IProduct;
}

export function ProductAmount(props: IProps) {
  return (
    <div className='amount-U3shsy'>
      Осталось: <i>{props.product.quantity}</i> {props.product.measureUnit}
    </div>
  );
}
