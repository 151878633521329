import { Button, Modal, Stack } from 'components';
import { useImmer } from 'use-immer';
import './style.css';
import { ApiOrderService } from 'common/api';
import { CartService } from 'common/service';
import { ICartProductFull } from 'common/types';

interface IState {
  name: string;
  telephone: string;
  city: string;
  isDataError?: boolean;
  isSuccess?: boolean;
}

interface IProps {
  products: ICartProductFull[];
}

export function CreateOrderBlock(props: IProps) {
  const [state, updateState] = useImmer<IState>({
    name: '',
    telephone: '',
    city: '',
  });

  const handleCreateOrderClick = async () => {
    if (state.name.trim().length === 0 || state.city.trim().length === 0 || state.telephone.trim().length === 0) {
      updateState(x => {
        x.isDataError = true;
      });

      return;
    }

    const orderService = ApiOrderService.getInstance();
    const cartService = CartService.getInstance();

    await orderService.create(state.name.trim(), state.telephone.trim(), state.city.trim(), '', cartService.get());
    cartService.clear();
    updateState(x => {
      x.isSuccess = true;
    });
  };

  let productSumCount = 0;

  for (let index = 0; index < props.products.length; index++) {
    productSumCount += props.products[index].count;
  }

  return (
    <div>
      {state.isSuccess && (
        <Modal>
          <Stack
            orientation='vertical'
            className='error-modal-content-sHda'
            gap={20}
          >
            <h2>ЗАКАЗ СОЗДАН</h2>
            <p>Ваш заказ был успешно создан и пеередан в обработку менеджерам. Ожидайте звонка по указанному номеру.</p>
            <p>Обратите внимание, что менеджеры магазина работают по часовому поясу города Чита.</p>
            <Button href='/'>ВЫЙТИ НА ГЛАВНУЮ СТРАНИЦУ</Button>
          </Stack>
        </Modal>
      )}
      {state.isDataError && (
        <Modal
          onClose={() =>
            updateState(x => {
              x.isDataError = false;
            })
          }
        >
          <Stack
            orientation='vertical'
            className='error-modal-content-sHda'
            gap={20}
          >
            <h2>ОШИБКА</h2>
            <div>Чтобы оформить заказ, необходимо указать ваше имя, номер телефона и город, в который он будет доставлен</div>
            <Button
              onClick={() =>
                updateState(x => {
                  x.isDataError = false;
                })
              }
            >
              ЗАКРЫТЬ
            </Button>
          </Stack>
        </Modal>
      )}
      <Stack
        orientation='vertical'
        gap={10}
        className='create-order-jH3ys'
      >
        <h2>ОФОРМИТЬ ЗАКАЗ</h2>
        <Stack
          orientation='vertical'
          gap={10}
        >
          <input
            type='text'
            placeholder='Ваше имя *'
            className='order-field-Nshj2'
            value={state.name}
            onChange={e =>
              updateState(x => {
                x.name = e.target.value;
              })
            }
          />
          <input
            type='phone'
            placeholder='Ваш номер телефона *'
            className='order-field-Nshj2'
            value={state.telephone}
            onChange={e =>
              updateState(x => {
                x.telephone = e.target.value;
              })
            }
          />
          <input
            type='text'
            placeholder='Ваш город *'
            className='order-field-Nshj2'
            value={state.city}
            onChange={e =>
              updateState(x => {
                x.city = e.target.value;
              })
            }
          />
          <input
            type='button'
            value='ОСТАВИТЬ ЗАКАЗ'
            className='order-button-Kshdy'
            disabled={productSumCount <= 0}
            onClick={handleCreateOrderClick}
          />
        </Stack>
      </Stack>
    </div>
  );
}
