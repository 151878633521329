import { useEffect, useState } from 'react';
import { Button, Stack } from 'components';
import { IProduct } from 'common/models';
import { CartService } from 'common/service';
import './style.css';

interface IProps {
  product: IProduct;
}

export function ButtonAddToCart(props: IProps) {
  const [cartCount, setCartCount] = useState<number>(0);

  useEffect(() => {
    updateCartProductCount();
  }, []); // eslint-disable-line

  const updateCartProductCount = () => {
    const cartService = CartService.getInstance();
    const cartProducts = cartService.get();
    const cartProduct = cartProducts.find(x => x.productPermalink === props.product.permalink);
    const count = cartProduct?.count ?? 0;
    setCartCount(count);
  };

  const handleAddToCartClick = (count: number) => {
    const cartService = CartService.getInstance();
    cartService.set(props.product, cartCount + count);
    updateCartProductCount();
  };

  if (cartCount > 0) {
    return (
      <Stack
        className='cart-control-block-57Sd'
        orientation='horizontal'
        contentFill={['60px', 1, '60px']}
      >
        <Button onClick={() => handleAddToCartClick(-1)}>-</Button>
        <div>
          {cartCount} {props.product.measureUnit}
        </div>
        <Button onClick={() => handleAddToCartClick(1)}>+</Button>
      </Stack>
    );
  }

  return (
    <div className='cart-control-block-57Sd'>
      <Button onClick={() => handleAddToCartClick(1)}>ДОБАВИТЬ В КОРЗИНУ</Button>
    </div>
  );
}
