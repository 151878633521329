import { Link, Stack } from 'components';

interface IProps {
  href: string;
  icon: React.ReactNode;
  title: string;
}

export function LinkButtonMobile(props: IProps) {
  return (
    <div className='header-mobile-link-5HA'>
      <Link href={props.href}>
        <Stack
          orientation='horizontal'
          gap={10}
        >
          {props.icon}
          {props.title}
        </Stack>
      </Link>
    </div>
  );
}
