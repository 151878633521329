import { IProduct } from 'common/models';
import './style.css';

interface IProps {
  product: IProduct;
}

export function ProductTitle(props: IProps) {
  return <h1 className='product-title-lH4s'>{props.product.title}</h1>;
}
