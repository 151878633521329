import { ICategoryBrief } from 'common/models';
import { MessageCard, Stack } from 'components';
import { CategoriesBlock } from 'shared/components';
import { AboutCard, ContactsCard } from '../components';

export function DesktopAbout(props: { categories: ICategoryBrief[] }) {
  return (
    <Stack
      orientation='vertical'
      gap={20}
    >
      <Stack
        className='page-content-NAjSy'
        orientation='horizontal'
        gap={20}
        contentFill={['300px', 1]}
      >
        <CategoriesBlock categories={props.categories} />

        <Stack
          orientation='horizontal'
          contentFill={[1, 0]}
        >
          <Stack
            orientation='vertical'
            gap={10}
          >
            <h1>О НАС</h1>
            <AboutCard />
            <ContactsCard />
          </Stack>
          <div className='content-filler-nAJsy'></div>
        </Stack>
      </Stack>
    </Stack>
  );
}
